import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import FileUploadInput from "../../../components/FileUploadInput";
import {
  SPREADSHEET,
  ARCHIVE_FILE
} from '../../../constants/runNowParametersNames';

const CrawlRunNowModalBodySpreadsheet = ({
  parameters,
  setParameter
}) => {
  // spreadsheet
  const uploadedSpreadsheets = _.get(parameters, [SPREADSHEET], []);

  // archive
  const uploadedArchives = _.get(parameters, [ARCHIVE_FILE], []);

  return (
    <>
      <FileUploadInput
        label="Spreadsheet Upload"
        onChange={setParameter}
        accept='.csv, .xls, .txt, .xlsx, .xlsm, .xlsb, .odf, .tsv'
        name={SPREADSHEET}
        uploadedFiles={uploadedSpreadsheets}
      />
      <FileUploadInput
        label="Zip File Upload"
        onChange={setParameter}
        accept='.zip'
        name={ARCHIVE_FILE}
        uploadedFiles={uploadedArchives}
      />
    </>
  );
};

CrawlRunNowModalBodySpreadsheet.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func
};

CrawlRunNowModalBodySpreadsheet.defaultProps = {
  parameters: {},
  setParameter: () => {}
};

export default CrawlRunNowModalBodySpreadsheet;

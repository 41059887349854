import {
  STARTING_DATE,
  FINISHING_DATE,
  SPREADSHEET_NAME,
  ARCHIVE_FILE_NAME
} from './runNowParametersNames';

// types of run now
/*
  value - used by CrawlRunNowModalBody component to choose type of render
  label - displayed name
  commandName - command name using by backend
  commandRequestName - type that should be send like run_type
  requiredValues - required fields in parameters of command request
  update_existing_documents - enable / disable switch option for update
  force_update_existing_documents - enable / disable switch option for update
  versioning_existing_documents - enable / disable switch option for update
  crawl_run_debug_mode - enable / disable switch option for degug trace genearation
 */
export default {
  horizontal_split: {
    value: 'horizontal_split_run',
    commandRequestName: 'horizontal_split_run'
  },
  archive: {
    value: 'archive',
    commandName: 'on_demand_crawl_run',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: true,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false,
    cursor_run: false,
    auto_resume: false
  },
  archive_email: {
    value: 'archive_email',
    commandName: 'run_now_email_crawl',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: true,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  },
  archive_feedly: {
    value: 'archive_feedly',
    commandName: 'run_now_feedly_crawls',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  },
  archive_codebot: {
    value: 'archive_codebot',
    commandName: 'run_now_codebot_crawls',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  },
  archive_custom: {
    value: 'archive_custom',
    commandName: 'run_now_custom_crawl',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  },
  archive_osapi: {
    value: 'archive_osapi',
    commandName: 'run_now_osapi_crawls',
    commandRequestName: 'archive',
    label: 'Archive',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  },
  pub_date_interval: {
    value: 'pub_date_interval',
    commandName: 'on_demand_crawl_run',
    commandRequestName: 'pub_date_interval',
    label: 'Publication Date Interval',
    update_existing_documents: true,
    force_update_existing_documents: true,
    versioning_existing_documents: false,
    requiredValues: [STARTING_DATE, FINISHING_DATE],
    debug_mode: false,
    video_mode: false,
    cursor_run: false,
    auto_resume: false
  },
  pub_date_interval_feedly: {
    value: 'pub_date_interval_feedly',
    commandName: 'run_now_feedly_crawls',
    commandRequestName: 'pub_date_interval',
    label: 'Publication Date Interval',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    requiredValues: [STARTING_DATE, FINISHING_DATE],
    debug_mode: false,
    video_mode: false
  },
  upload_doc_spreadsheet: {
    value: 'upload_doc_spreadsheet',
    commandName: 'crawl_spreadsheet_documents',
    commandRequestName: 'upload_doc_spreadsheet',
    label: 'Upload Document Spreadsheet',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    requiredValues: [
      /*
        there is spreadsheetName not spreadsheet
        because we send name in parameters
        but file we attach to formData in request
        (logic: if name exist file was provided)
       */
      SPREADSHEET_NAME
    ],
    debug_mode: false,
    video_mode: false
  },
  upload_premium_docs: {
    value: 'upload_premium_docs',
    commandName: 'crawl_spreadsheet_documents',
    commandRequestName: 'upload_doc_spreadsheet',
    label: 'Upload Document Spreadsheet',
    update_existing_documents: true,
    force_update_existing_documents: false,
    versioning_existing_documents: false,
    requiredValues: [
      /*
        there is spreadsheetName/spreadsheetName not spreadsheet/archiveFile
        because we send name in parameters
        but file we attach to formData in request
        (logic: if name exist file was provided)
       */
      SPREADSHEET_NAME,
      ARCHIVE_FILE_NAME
    ]
  },
  dry_run: {
    value: 'dry_run',
    commandName: 'dry_run',
    commandRequestName: 'dry_run',
    label: 'Upload Document Spreadsheet',
    update_existing_documents: true,
    force_update_existing_documents: true,
    versioning_existing_documents: false,
    debug_mode: false,
    video_mode: false
  }
};

/*
  in future values should be change to
  on_demand_crawl_run -> "Run Crawler"
  run_now_custom_crawl -> "Run Now Command for custom crawls"
  crawl_spreadsheet_documents -> "Crawl spreadsheet documents"
*/

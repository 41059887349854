import type { CrawlDetails, ModalModeSet } from './CrawlerDetails.types';
import type { ExternalApiCrawlFromResponse } from 'shared/features/externalApiCrawl/externalApiCrawl.api.types';

import * as uiLib from '@compliance.ai/web-components';

export const CRAWL_GENERAL = {
  spiderName: 'spider_name',
  crawlType: 'crawler_type',
  jurisdiction: 'jurisdiction',
  crawlID: 'crawl_id',
  pubInPast: 'publish_in_past',
  pubInPastValue: 'publish_in_past_value',
  crawlerRecurActive: 'recur_enabled',
  frequency: 'schedule_frequency',
  agencies: 'agencies',
  newsSource: 'news_source',
  docType: 'document_type',
  devComplexity: 'dev_complexity',
  devComplexityClassification: 'dev_complexity_classification',
  eitlLabels: 'eitl_labels'
};

export const CRAWL_SCRAPED = {
  scrapedCategory: 'doc_scraped_categories',
  scrapedNewsSource: 'doc_scraped_news_sources',
  scrapedAgencies: 'doc_scraped_agencies',
  scrapedCAICategories: 'doc_scraped_cai_categories'
};

export const CRAWL_LINKS = {
  sourceURL: 'source_url',
  JIRA: 'associate_jira_tickets',
  other: 'other_links',
  QA: 'qa_spreadsheet',
  bucket: 's3_bucket',
  specification: 'specification'
};

export const CRAWL_PROVISION = {
  nodeURL: 'root_node_url',
  rootShelfId: 'root_shelf_id',
  docket: 'dockets',
  isResource: 'is_resource',
  isCheckAll: 'is_check_all',
  useProxy: 'use_proxy',
  startingDate: 'starting_date',
  notRequirePubDate: 'pub_date_not_required',
  sourceStructure: 'source_structure',
  sentencificationConfiguration: 'sentencification_configuration',
  enableAutoTranslation: 'enable_translation',
  enableDocumentVersioning: 'enable_document_versioning'
};

export const CRAWL_PRIVACY = {
  access: 'restricted_access'
};

export const CRAWL_EXTRA_RECURRING = {
  updateDocuments: 'update_documents',
  emptyUpdate: 'without_update',
  forceUpdate: 'force_update_existing_documents',
  versioning: 'versioning_existing_documents',
  softUpdate: 'update_existing_documents',
  cursorRun: 'crawl_cursor_run_mode',
  autoResume: 'crawl_auto_resume_mode'
};

export const PUB_IN_PAST = ['Day', 'Week', 'Month'];

// Errors

export const ERRORS = {
  rootNodeURL: 'Please check that your URL is valid and contain summary_id',
  commonError: 'This value is required',
  crawlId: 'Crawl id should contain spider_name',
  devComplexityError: 'This value is required for unified crawler',
  sourceStructure: 'Please check the source has valid structure',
  sentencificationConfiguration: 'Please check the crawl has valid provisioning'
};

export const ERROR_MODAL_ACTION = {
  type: uiLib.ALERT_TYPES.ERROR,
  title: 'Modal action error',
  message: 'Sorry, something went wrong. please check your inputs and try again.'
};

// --- Predefined state

// crawls details use by CrawlerDetails component (just help to show structure)
export const CRAWL_DETAILS: CrawlDetails = {
  // general info
  [CRAWL_GENERAL.agencies]: [], // array of { value: "", label: "" }
  [CRAWL_GENERAL.crawlType]: null, // { value: "", label: "" }
  [CRAWL_GENERAL.docType]: [], // array of { value: "", label: "" }
  [CRAWL_GENERAL.jurisdiction]: null, // { value: "", label: "" }
  [CRAWL_GENERAL.newsSource]: [], // array of { value: "", label: "" }
  [CRAWL_GENERAL.pubInPast]: null, // { value: "", label: "" }
  [CRAWL_GENERAL.pubInPastValue]: '', // string (only numbers)
  [CRAWL_GENERAL.frequency]: '', // string (only numbers)
  [CRAWL_GENERAL.crawlerRecurActive]: false, // boolean
  [CRAWL_GENERAL.crawlID]: '', // string
  [CRAWL_GENERAL.spiderName]: '', // string
  [CRAWL_GENERAL.devComplexity]: '0', // string | number
  [CRAWL_GENERAL.devComplexityClassification]: 'Not defined', // string
  [CRAWL_GENERAL.eitlLabels]: [], // array of { value: "", label: "" }
  // scrapped info
  [CRAWL_SCRAPED.scrapedCategory]: [], // array
  [CRAWL_SCRAPED.scrapedNewsSource]: [], // array
  [CRAWL_SCRAPED.scrapedAgencies]: [], // array
  [CRAWL_SCRAPED.scrapedCAICategories]: [], // array
  // links
  [CRAWL_LINKS.sourceURL]: [], // array of string
  [CRAWL_LINKS.JIRA]: [], // array of string
  [CRAWL_LINKS.other]: [], // array of string
  [CRAWL_LINKS.QA]: '', // string
  [CRAWL_LINKS.bucket]: '', // string
  [CRAWL_LINKS.specification]: '', // string
  // provision
  [CRAWL_PROVISION.docket]: '', // string (array joined by ',')
  [CRAWL_PROVISION.nodeURL]: '', // string
  [CRAWL_PROVISION.rootShelfId]: '', // string
  [CRAWL_PROVISION.isResource]: false, // boolean
  [CRAWL_PROVISION.notRequirePubDate]: false, // boolean
  [CRAWL_PROVISION.sourceStructure]: '', // string
  [CRAWL_PROVISION.sentencificationConfiguration]: '', // string
  [CRAWL_PROVISION.enableAutoTranslation]: null, // boolean | null
  [CRAWL_PROVISION.enableDocumentVersioning]: false, // boolean
  [CRAWL_PROVISION.useProxy]: false, // boolean
  [CRAWL_PROVISION.isCheckAll]: false, // boolean
  [CRAWL_PROVISION.startingDate]: null, // no date
  // privacy
  [CRAWL_PRIVACY.access]: false, // boolean
  // extra recurring params
  [CRAWL_EXTRA_RECURRING.versioning]: false, // bool
  [CRAWL_EXTRA_RECURRING.forceUpdate]: false, // bool
  [CRAWL_EXTRA_RECURRING.softUpdate]: false, // bool
  [CRAWL_EXTRA_RECURRING.cursorRun]: false, // bool
  [CRAWL_EXTRA_RECURRING.autoResume]: false // bool
};

export const MODE_CONFIRMATION_MODAL: ModalModeSet = {
  EDIT_SOURCE_STRUCTURE: {
    title: 'Edit Source Structure',
    body: 'You are changing the source structure that should be scraped',
    type: 'EDIT_SOURCE'
  }
};

export const getDevComplexityClassification = (
  crawlInfo: ExternalApiCrawlFromResponse | CrawlDetails
): string => {
  const complexity = Number(crawlInfo?.dev_complexity);
  if (!complexity || complexity < 1) {
    return 'Not defined';
  }
  if (complexity < 15) {
    return 'Simple';
  }
  if (complexity < 27) {
    return 'Low';
  }
  if (complexity < 33) {
    return 'Medium';
  }
  if (complexity < 38) {
    return 'High';
  }
  return 'Huge';
};

export enum AUTO_TRANSLATION_TYPE {
  DEFAULT = 'default',
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export const ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS = [
  {
    type: AUTO_TRANSLATION_TYPE.DEFAULT,
    label: 'Default',
    value: null
  },
  {
    type: AUTO_TRANSLATION_TYPE.ENABLED,
    label: 'Enabled',
    value: true
  },
  {
    type: AUTO_TRANSLATION_TYPE.DISABLED,
    label: 'Disabled',
    value: false
  }
];

export const DEFAULT_AUTO_TRANSLATION_OPTION = ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS[0];

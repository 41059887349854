import React from 'react';

import * as elements from './elements';

import _ from 'lodash';
import CrawlerCatalogRightPanel from './CrawlerCatalogRightPanel';
import CrawlerCatalogBody from './CrawlerCatalogBody';
import CrawlerCatalogDryRun from './CrawlerCatalogDryRun';
import DryRunSettingsModal from './CrawlCatalogModals/DryRunSettingsModal';
import {
  getCrawlerStatistic,
  postExportCrawlInfo,
  postImportCrawlInfo
} from 'shared/features/crawlerSettings/crawlerActivity/crawlerActivity.actions';
import {
  clearCrawConfig,
  clearCrawDryRun,
  clearCrawSpec,
  getCrawlerRawConfig,
  postCrawlRawConfig,
  getCrawlerRawDryRun,
  getCrawlerSpecOptions
} from 'shared/features/crawlerSettings/crawlerDocuments/crawlerDocuments.actions';
import { createNewCommandRequest } from './CrawlerCatalog.helper';
import { COMMAND_REQUEST_NAMES } from '../../../constants/commandRequestTypes';
import {
  uploadNewCommandRequest,
  fetchAllCommandRequests
} from 'shared/features/commands/commands.actions';
import { setNotification, NOTIFICATION_TYPES } from '../../../components/Notification';
import {
  MODE_CONFIRMATION_MODAL,
  REQUIRED_CRAWL_DETAILS,
  REQUIRED_CRAWL_FILES,
  REQUIRED_IMPORT_INFO,
  ERROR_MODAL_ACTION,
  ERROR_EXPORT,
  ERROR_IMPORT,
  ERROR_CREATE_COMMAND_REQUEST,
  ERROR_UPDATE_CONFIG
} from './CrawlerCatalog.constants';
import RUN_NOW_TYPES from '../../../constants/runNowTypes';
import CrawlActivityExtraContentModal from '../../../components/CrawlActivityExtraContentModal/CrawlActivityExtraContentModal';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCrawlerCatalogData,
  useCrawlerCatalogHandlers,
  useCrawlerCatalogLifecycle
} from './hooks';

const CrawlsCatalog = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { localState, localActions, reduxState, formattedData } = useCrawlerCatalogData();

  const handlers = useCrawlerCatalogHandlers({
    localActions,
    formattedData
  });

  useCrawlerCatalogLifecycle({
    onInitialCrawlConfigChange: handlers.handleInitialCrawlConfigChange
  });

  useEffect(() => {
    dispatch(clearCrawSpec());
    dispatch(clearCrawConfig());
    dispatch(clearCrawDryRun());
    dispatch(getCrawlerRawConfig(params.id));
    dispatch(getCrawlerRawDryRun(params.id));
    dispatch(getCrawlerStatistic(params.id, { extendedCatalogInformation: true }));
    dispatch(getCrawlerSpecOptions());
    dispatch(
      fetchAllCommandRequests({
        crawl_id: params.id,
        command_name: [
          COMMAND_REQUEST_NAMES.DRY_RUN_CRAWLER_COMMAND,
          COMMAND_REQUEST_NAMES.SPEC_TO_CONFIG_CONVERSION
        ],
        crawl_command_request_type: true
      })
    );
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.timestamp]);

  const exportCrawl = () => {
    // check crawl info
    const crawlDetailsComplete = REQUIRED_CRAWL_DETAILS.every(item =>
      Array.isArray(item)
        ? item.some(optionalItem => _.get(reduxState.crawlDetails, optionalItem))
        : _.get(reduxState.crawlDetails, item)
    );
    const crawlFilesComplete = REQUIRED_CRAWL_FILES.every(item =>
      _.get(reduxState.crawlerActivity, item)
    );
    if (crawlDetailsComplete && crawlFilesComplete) {
      dispatch(postExportCrawlInfo(reduxState.crawlDetails.id));
      localActions.setShouldShowModal(false);
    } else {
      setNotification({ message: ERROR_EXPORT, type: NOTIFICATION_TYPES.ERROR });
    }
  };

  const importCrawl = () => {
    // check crawl info
    const crawlFilesComplete = REQUIRED_IMPORT_INFO.every(item =>
      _.get(reduxState.crawlerActivity, item)
    );
    if (crawlFilesComplete) {
      dispatch(postImportCrawlInfo(reduxState.crawlDetails.id));
      localActions.setShouldShowModal(false);
    } else {
      setNotification({ message: ERROR_IMPORT, type: NOTIFICATION_TYPES.ERROR });
    }
  };

  const createCommandRequest = (commandName, extraParams = {}) => {
    const request = createNewCommandRequest({
      crawlDetailsInfo: reduxState.crawlDetails,
      commands: reduxState.commands,
      current_user: reduxState.currentUser,
      commandName,
      extraParams
    });
    if (request.error) {
      setNotification({ message: ERROR_CREATE_COMMAND_REQUEST, type: NOTIFICATION_TYPES.ERROR });
      return;
    }
    dispatch(uploadNewCommandRequest(request));
    localActions.setShouldShowModal(false);
    localActions.setShouldShowDryRunModal(false);
  };

  const saveCrawlConfig = () => {
    let data = null;
    try {
      data = JSON.parse(localState.crawlConfig);
    } catch (error) {
      setNotification({ message: ERROR_UPDATE_CONFIG, type: NOTIFICATION_TYPES.ERROR });
      localActions.setShouldShowModal(false);
      return;
    }
    dispatch(postCrawlRawConfig(data));
    localActions.setShouldShowViewModal(false);
    localActions.setShouldShowModal(false);
  };

  const openModalWithMode = currentMode => {
    localActions.setMode(currentMode);
    localActions.setShouldShowModal(true);
  };

  const closeDryRunInfo = () => {
    navigator.clipboard.writeText(localState.mode.body);
    localActions.setShouldShowModal(false);
    localActions.setMode(MODE_CONFIRMATION_MODAL.EXPORT);
  };

  const createDryRunRequest = (amountDocs, isVideoRecordingMode) => {
    const extraParams = {
      run_type: RUN_NOW_TYPES.dry_run.value,
      docs_limit: _.toNumber(amountDocs),
      crawler_type: 'unified',
      crawl_run_video_mode: isVideoRecordingMode
    };
    createCommandRequest(COMMAND_REQUEST_NAMES.DRY_RUN_CRAWLER_COMMAND, extraParams);
  };

  const modalAction = async () => {
    switch (localState.mode.title) {
      case MODE_CONFIRMATION_MODAL.EXPORT.title:
        exportCrawl();
        break;
      case MODE_CONFIRMATION_MODAL.IMPORT.title:
        importCrawl();
        break;
      case MODE_CONFIRMATION_MODAL.CONVERT.title:
        createCommandRequest(COMMAND_REQUEST_NAMES.SPEC_TO_CONFIG_CONVERSION);
        break;
      case MODE_CONFIRMATION_MODAL.EDIT_CONFIG.title:
        saveCrawlConfig();
        break;
      case MODE_CONFIRMATION_MODAL.DRY_RUN_INFO.title:
        closeDryRunInfo();
        break;

      default:
        setNotification({ message: ERROR_MODAL_ACTION, type: NOTIFICATION_TYPES.ERROR });
    }
  };

  const processDryRunInfoModal = ({ reason }) => {
    openModalWithMode({
      ...MODE_CONFIRMATION_MODAL.DRY_RUN_INFO,
      body: reason
    });
  };

  return (
    <div className="crawlCatalog">
      <div className="crawlCatalog_body-wrapper">
        <CrawlerCatalogBody
          crawlerActivity={reduxState.crawlerActivity}
          disableEdit={formattedData.isEditDisabled}
          modalAction={() => openModalWithMode(MODE_CONFIRMATION_MODAL.EDIT_CONFIG)}
          setCrawlConfig={localActions.setCrawlConfig}
          crawlConfig={localState.crawlConfig}
        />
        <CrawlerCatalogRightPanel
          openExportModal={() => openModalWithMode(MODE_CONFIRMATION_MODAL.EXPORT)}
          openImportModal={() => openModalWithMode(MODE_CONFIRMATION_MODAL.IMPORT)}
          openConvertModal={() => openModalWithMode(MODE_CONFIRMATION_MODAL.CONVERT)}
          openViewModal={() => localActions.setShouldShowViewModal(true)}
          crawlerActivity={reduxState.crawlerActivity}
          crawlDetails={reduxState.crawlDetails}
          crawlerDocuments={reduxState.crawlerDocuments}
          convertCommandRequest={reduxState.commandRequests.find(
            item =>
              _.get(item, ['type', 'name']) === COMMAND_REQUEST_NAMES.SPEC_TO_CONFIG_CONVERSION
          )}
        />
      </div>
      <CrawlerCatalogDryRun
        crawlerDocuments={reduxState.crawlerDocuments}
        crawlerActivity={reduxState.crawlerActivity}
        disableEdit={formattedData.isEditDisabled}
        openConfirmation={() => localActions.setShouldShowDryRunModal(true)}
        showInfo={processDryRunInfoModal}
        shouldShowInfo={handlers.handleOpenModal}
        dryRunCommandRequest={reduxState.commandRequests.find(
          item => _.get(item, ['type', 'name']) === COMMAND_REQUEST_NAMES.DRY_RUN_CRAWLER_COMMAND
        )}
      />
      {localState.shouldShowViewModal && (
        <elements.EditCrawlConfigModal
          onSubmit={handlers.handleEditCrawlConfigModalSubmit}
          onClose={handlers.handleEditCrawlConfigModalClose}
          initialCrawlConfig={formattedData.initialCrawlConfig}
          crawlConfig={localState.crawlConfig}
          onCrawlConfigChange={localActions.setCrawlConfig}
          isEditDisabled={formattedData.isEditDisabled}
        />
      )}
      <DryRunSettingsModal
        show={localState.shouldShowDryRunModal}
        onHide={() => localActions.setShouldShowDryRunModal(false)}
        modalAction={createDryRunRequest}
      />
      {localState.shouldShowModal && (
        <elements.ConfirmationModal
          mode={localState.mode}
          onSubmit={modalAction}
          onClose={() => localActions.setShouldShowModal(false)}
        />
      )}
      <CrawlActivityExtraContentModal
        show={localState.extraContentModalData}
        onHide={() => localActions.setExtraContentModalData(null)}
      />
    </div>
  );
};

export default CrawlsCatalog;

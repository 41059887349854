// there are names of fields that should be send in backend
// // formData names
export const COMMAND_REQUEST_PARAMETERS = 'command_request_parameters';
// // also in form data we use spreadsheet, archiveFile
// // common names
export const CRAWL_ID = 'crawl_id';
export const CRAWL_TYPE = 'crawler_type';
export const SPIDER_NAME = 'spider_name';
export const RUN_TYPE = 'run_type';
// // // be attentive with the next 5 fields, they used for backend purpose
export const UPDATE_FIELD = 'update_documents';
export const UPDATE_FIELD_WITHOUT = 'without_update';
export const UPDATE_FIELD_SOFT = 'update_existing_documents';
export const UPDATE_FIELD_FORCE = 'force_update_existing_documents';
export const UPDATE_FIELD_VERSIONING = 'versioning_existing_documents';
export const DEBUG_MODE = 'crawl_run_debug_mode';
export const VIDEO_MODE = 'crawl_run_video_mode';
export const CURSOR_RUN = 'crawl_cursor_run_mode';
export const AUTO_RESUME = 'crawl_auto_resume_mode';
// // spreadsheet names
export const SPREADSHEET = 'attached_file';
export const SPREADSHEET_NAME = 'file_name'; // take automatically from spreadsheet
export const ARCHIVE_FILE = 'attached_additional_file';
export const ARCHIVE_FILE_NAME = 'additional_file_name'; // take automatically from archiveFile
// // publication date names
export const STARTING_DATE = 'starting_date';
export const FINISHING_DATE = 'finishing_date';
// custom crawl Archive
// // custom - inputs
export const MAX_INGESTED = 'max_ingested';
export const YEARS = 'years';
export const YEAR = 'year';
export const WEEKS_AGO = 'weeks_ago';
export const CONSTRUCTOR_PARAMS = 'constructor_params'; // need extra wrap 'year' {constructor_params:{year: value}}
export const DATE_FILTER = 'date_filter';
export const DATE_TODAY = 'date_today';
export const AREAS_INTEREST = 'areas_of_interest';
// // custom - selects
export const AGENCIES = 'agencies';
export const SECTIONS = 'sections';
// unified crawl Archive
export const ARCHIVE_UNIFIED = {
  SPLIT_RUN: 'horizontal_split_run',
  DATE_START: 'horizontal_starting_date',
  DATE_FINISH: 'horizontal_finishing_date'
};
